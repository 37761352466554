<template>
  <myoders />
</template>

<script>

import myoders from "@/components/myOders.vue";


export default {
  components: { myoders }
};
</script>
